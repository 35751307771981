import * as React from 'react'
import './Container.module.css'

const Container = ({children}) => {
    return (
        <body>
        <h1>Test</h1>
        <br/>
        {children}
        </body>
    )
}

export default Container