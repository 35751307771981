import * as React from 'react'
import Container from '../components/Container'

const hello = () => {
    return(
        <Container>
            Hello hello hello
        </Container>
    )
}

export default hello